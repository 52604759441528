<template>
  <span :class="className2">{{ displayText }}</span>
</template>
<script>
import currencyHelper from '@/utils/currency-helper';
import BigNumber from 'bignumber.js';
import i18n from '@/i18n';

const NUM_OF_DECIMAL_DIGIT_CURRENCIES = currencyHelper.getNumOfDecimalDigitCurrencies();

export default {
  name: 'StakingCurrency',
  data() {
    return {};
  },
  props: {
    value: {
      type: [String, Number],
      default: null,
    },
    currencySymbol: {
      type: String,
      default: null,
    },
    className: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: 'small',
    },
    displayType: {
      type: String,
      default: 'text',
    },
    decimalScale: {
      type: Number,
      default: null,
    },
  },
  computed: {
    className2() {
      return `staking-currency ${this.className} staking-currency-${this.size}`;
    },
    symbolOnLeft() {
      return this.mappingCurrencySymbol === '$' ? true : false;
    },
    mappingCurrencySymbol() {
      const currencySymbol = currencyHelper.getMappingCurrencySymbol(this.currencySymbol);

      return currencySymbol;
    },
    bigValue() {
      const numOfDecimalDigits = NUM_OF_DECIMAL_DIGIT_CURRENCIES[this.mappingCurrencySymbol] || 0;
      if (numOfDecimalDigits === 0) {
        return new BigNumber(this.value).toNumber();
      }

      const result = new BigNumber(this.value).div(Math.pow(10, numOfDecimalDigits)).toFixed(numOfDecimalDigits);

      return result;
    },
    decimalDigits() {
      const { bigValue, decimalScale, mappingCurrencySymbol } = this;
      const decimalDigits = currencyHelper.getFormatDecimalDigits(bigValue, decimalScale, mappingCurrencySymbol);

      return decimalDigits;
    },
    displayText() {
      const { bigValue, decimalDigits, mappingCurrencySymbol } = this;
      const result = currencyHelper.formatCurrency(bigValue, mappingCurrencySymbol, decimalDigits);

      return result;
    },
  },
  methods: {},
};
</script>

<style lang="scss">
.staking-currency {
  &.staking-currency-large {
    font-size: 1.5em;
  }
  &.staking-currency-medium {
    font-size: 1.2em;
  }
  &.staking-currency-small {
    font-size: 14px;
  }
  &.primary {
    font-weight: bold;
  }
  &.secondary {
    color: 'gray';
  }
}
</style>
